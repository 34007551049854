:root {
    --color-scheme-background:white;
    --color-scheme-text-color:black;

    --mdc-theme-primary:black;
    --mdc-theme-secondary:#777;
    --mdc-theme-on-primary:white;
    --mdc-theme-on-secondary:yellow;
}

@media (prefers-color-scheme: light) {
    :root {
	/* Keep the default values. */
    }
}

@media (prefers-color-scheme: dark) {
    :root {
	/* Keep the default values. */
    }
}

body {
    background: var(--color-scheme-background);
    color: var(--color-scheme-text-color);
}

label, input {
    display: inline-block;
}

input {
    position: relative;
    float: right;
    right: 0;
}

.mdc-card {
    padding: 10px;
    margin: 3px;
}

.my-card-content {
  padding: 16px;
}

section.remindersSection {
    display: flex;
    flex-wrap: wrap;
}
